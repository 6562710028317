<template>
  <b-alert v-if="showProgressBar" class="alerta" variant="warning" show>
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="w-75 mb-1" :class="{ 'w-100': (settingsView || isSmall) }">
        <div class="ml-25 d-flex flex-wrap mt-50 mb-75">
          <span style="text-transform: uppercase" class="text-center mr-25">
            {{ $t("missing-information-title-user") }}
          </span>
          <span style="font-weight: normal">
            {{ $t("missing-information-subtitle-user") }}
          </span>
        </div>
        <div class="w-100 mt-50">
          <b-progress
            :max="numberOfFields"
            :value="completedFieldsCount"
            variant="warning"
            animated
          />
          <small class="font-weight-bold mt-50">
            {{ percentaje }}% {{ $t("completed") }}
          </small>
          <small v-if="emptyFieldsCount" class="font-weight-bold mt-50">
             {{ missingFieldsText }}
          </small>
          <small v-else class="font-weight-bold mt-50">
            !   🙌 🚀 🚀 🚀
          </small>
        </div>
      </div>
      <b-button v-if="!settingsView" variant="warning" :to="location" class="mt-1 mt-lg-0">
        {{ $t("missing-information-subsubtitle-user") }}
      </b-button>
    </div>
  </b-alert>
</template>
<script>
export default {
  name: "ProfileProgressBar",
  props: {
    memberInfo: Object,
    settingsView: Boolean,
  },
  data() {
    return {
      showProgressBar: false,
      emptyFieldsCount: 0,
      completedFieldsCount: 0,
      isSmall: false,
      emptyFields: [],
      missingFieldsText: ''
    };
  },
  computed: {
    location() {
      return {
        name: "globalMemberSettings",
        params: { communityId: this.$route.params.communityId },
      };
    },
    allFields() {
      return {
        //basic
        'name': this.memberInfo.name,
        'surname': this.memberInfo.surname,
        'headline': this.memberInfo.headline,
        'summary': this.memberInfo.summary,
        'backgroundURL': this.memberInfo.backgroundURL,
        'avatarURL': this.memberInfo.avatarURL,
        //other
        'emails': this.memberInfo.emails,
        'phones': this.memberInfo.phones,
        'links': this.memberInfo.links,
        //'locations': this.memberInfo.locations,
        'organizations': this.memberInfo.organizations,
      };
    },
    numberOfFields(){
      return Object.keys(this.allFields).length;
    },
    percentaje() {
      const result = ((this.numberOfFields - this.emptyFieldsCount) * 100) / this.numberOfFields;
      return Math.round(result);
    },
  },
  watch: {
    async memberInfo(value) {
      await this.isComplete();
      return value
    },
  },
  async created() {
    await this.isComplete();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 600;
    },
    isComplete() {
      this.showProgressBar = false;
      this.missingFieldsText = '';
      this.emptyFieldsCount = 0;
      this.completedFieldsCount = 0;
      // console.log('this.allFields', this.allFields);
      Object.entries(this.allFields).forEach(entry => {
        const [key, value] = entry;
        // console.log('key', key);
        // console.log('value', value);
        if(value === null || (this.allFields[key] && this.allFields[key].length <= 0)){
          if(this.emptyFieldsCount > 0){
            this.missingFieldsText += ', ';
          }else{
            this.missingFieldsText += ' (' + this.$t('missing') + ': ';
          }
          this.emptyFields.push(key);
          this.emptyFieldsCount += 1;
          this.missingFieldsText += key;
        }else{
          this.completedFieldsCount += 1;
        }
      });
      //console.log('emptyFields', this.emptyFields);
      if(this.emptyFieldsCount){
        this.missingFieldsText += ')';
      }
      this.showProgressBar = !!this.emptyFieldsCount;
    },
  },
};
</script>
